import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class SessionService {
  sideMenuUrlMap;
  constructor(private router: Router) { 
    this.sideMenuUrlMap = new Map();
    this.sideMenuUrlMap.set('app.deviceHealth','/admin/system-health')
    this.sideMenuUrlMap.set('app.customer','/admin/customer')
    this.sideMenuUrlMap.set('app.facility','/admin/facility')
    this.sideMenuUrlMap.set('app.device','/admin/device')
    this.sideMenuUrlMap.set('app.rbDataProcess','/admin/log/rightbox-data-process')
    this.sideMenuUrlMap.set('app.rightbox','/admin/log/rightbox')
    this.sideMenuUrlMap.set('app.rightBoxControlAppLog','/admin/log/rightbox-control-app')
    this.sideMenuUrlMap.set('app.rightBoxInitializationRequest','/admin/log/rightbox-initislization-request')
    this.sideMenuUrlMap.set('app.api','/admin/log/rightbox-api')
    this.sideMenuUrlMap.set('app.rightboxMessage','/admin/log/rightbox-message')
    this.sideMenuUrlMap.set('app.partnerLog','/admin/log/partner')
    this.sideMenuUrlMap.set('app.adminui','/admin/log/admin')
    this.sideMenuUrlMap.set('app.aggregationJobLog','/admin/log/aggregation-job')
    this.sideMenuUrlMap.set('app.ui','/admin/log/customer')
    this.sideMenuUrlMap.set('app.backgroundJobs','/admin/log/background-job')
    this.sideMenuUrlMap.set('app.customerUserLogin','/admin/log/customer-user-login')
    this.sideMenuUrlMap.set('app.adminUserLogin','/admin/log/admin-user-login')
    this.sideMenuUrlMap.set('app.deviceControl','/admin/audit-log/device-control')
    
    this.sideMenuUrlMap.set('app.userList','/admin/customer-user/user')
    this.sideMenuUrlMap.set('app.manageUserRole','/admin/customer-user/role')
    this.sideMenuUrlMap.set('app.manageUserRolePermission','/admin/customer-user/role-permission')
    this.sideMenuUrlMap.set('app.sldLabel','/admin/sld/label')
    this.sideMenuUrlMap.set('app.sldDeviceMapping','/admin/sld/device-map')

    this.sideMenuUrlMap.set('app.recommendations','/admin/general/recommendation')
    this.sideMenuUrlMap.set('app.reportTypeUtility','/admin/general/report-utility-charge')
    this.sideMenuUrlMap.set('app.fieldValidation','/admin/field-validation')

    // device profile
    this.sideMenuUrlMap.set('app.deviceProfile','/admin/device-profile')

    // control App
    this.sideMenuUrlMap.set('app.controlApplication','/admin/control-app')
    
    // Mimic Assets
    this.sideMenuUrlMap.set('app.deviceImage','/admin/mimic-asset')
    this.sideMenuUrlMap.set('app.scadavisImage','/admin/scadavis-image')

    // rightbox
    this.sideMenuUrlMap.set('app.rightboxes','/admin/rightbox')
    this.sideMenuUrlMap.set('app.rightboxExcelUpload','/admin/rightbox/excel-upload')
    this.sideMenuUrlMap.set('app.rbDeviceMap','/admin/rightbox/device-map')
    this.sideMenuUrlMap.set('app.rightboxMapView','/admin/rightbox/rightbox-map')
    
    // rightbox-notification
    this.sideMenuUrlMap.set('app.rightboxConfiguration','/admin/rightbox-notification/rightbox-software-upgrade')
    this.sideMenuUrlMap.set('app.rightboxApiNotification','/admin/rightbox-notification/rightbox-pull-notification')
    this.sideMenuUrlMap.set('app.rightboxNotification','/admin/rightbox-notification/rightbox-push-notification')

    // internal utility 
    this.sideMenuUrlMap.set('app.passwordGenerator','/admin/internal-utility/password-generator')
    this.sideMenuUrlMap.set('app.appStoreEnquiry','/admin/internal-utility/appstore-enquiry')
    this.sideMenuUrlMap.set('app.manageAggregationJobLable','/admin/internal-utility/aggregation-job-label')

    // Partner
    this.sideMenuUrlMap.set('app.partner','/admin/partner')
    this.sideMenuUrlMap.set('app.partnerUser','/admin/partner-user/user')
    this.sideMenuUrlMap.set('app.partnerUserRole','/admin/partner-user/role')
    this.sideMenuUrlMap.set('app.partnerUserRolePermission','/admin/partner-user/role-permission')
    this.sideMenuUrlMap.set('app.partnerCustomerMap','/admin/partner/customer-map')
    this.sideMenuUrlMap.set('app.partnerRightboxMap','/admin/partner/rightbox-map')

    // super admin side menu
    this.sideMenuUrlMap.set('app.manageAdminUserRole','/admin/admin-user/role')
    this.sideMenuUrlMap.set('app.manageAdminUserRolePermission','/admin/admin-user/role-permission')
    this.sideMenuUrlMap.set('app.adminUserList','/admin/admin-user/user')
    this.sideMenuUrlMap.set('app.adminUserFacility','/admin/admin-user/user-facility')
    this.sideMenuUrlMap.set('app.adminUserRightbox','/admin/admin-user/user-rightbox')

    //approve
    this.sideMenuUrlMap.set('app.approveApp','/admin/approve/app')
    // custom analysis view
    this.sideMenuUrlMap.set('app.view','/admin/custom-analysis')

    this.sideMenuUrlMap.set('app.rightboxRealTimeEvent','/admin/rightbox-event')
    // manage(prepaid)
    this.sideMenuUrlMap.set('app.prepaidBalanceHistory','/admin/balance-history')
    // KPI
    this.sideMenuUrlMap.set('app.kpiExcelUpload','/admin/kpi/excel-upload')
    this.sideMenuUrlMap.set('app.rightboxRealtimeExcelUpload','/admin/rightbox-event/excel-upload')

  }

  setUser(userObj) {
    userObj = JSON.stringify(userObj);
    localStorage.setItem('rwAdmin', userObj);
  }

  getUser() {
    let userObj = localStorage.getItem('rwAdmin');
    userObj = JSON.parse(userObj);
    return userObj;
  }

  setCustomer(customerObj) {
    customerObj = JSON.stringify(customerObj);
    localStorage.setItem('rwDefaultCustomer', customerObj);
  }

  getCustomer() {
    let customerObj = localStorage.getItem('rwDefaultCustomer');
    customerObj = JSON.parse(customerObj);
    return customerObj;
  }

  setFacility(facilityObj) {
    facilityObj = JSON.stringify(facilityObj);
    localStorage.setItem('rwDefaultFacility', facilityObj);
  }

  getFacility() {
    let facilityObj = localStorage.getItem('rwDefaultFacility');
    facilityObj = JSON.parse(facilityObj);
    return facilityObj;
  }

  setSelectedPartner(partnerObj) {
    partnerObj = JSON.stringify(partnerObj);
    localStorage.setItem('partner', partnerObj);
  }

  getSelectedPartner() {
    let partnerObj = localStorage.getItem('partner');
    partnerObj = JSON.parse(partnerObj);
    return partnerObj;
  }

  setDeviceProfile(deviceProfileObj) {
    deviceProfileObj = JSON.stringify(deviceProfileObj);
    localStorage.setItem('deviceProfile', deviceProfileObj);
  }

  getDeviceProfile() {
    let deviceProfileObj = localStorage.getItem('deviceProfile');
    deviceProfileObj = JSON.parse(deviceProfileObj);
    return deviceProfileObj;
  }

  getUserPermissionForPage(ComponentUrl) {
    let userObj: any = localStorage.getItem('rwAdmin');
    userObj = JSON.parse(userObj);
    if (userObj && userObj.menus) {
      if (userObj.AdminRoleId == 1) {
        return { fullAccess: true };
      } else {
        for (var i = 0; i < userObj.menus.length; i++) {
          if (userObj.menus[i].submenus) {
            for (var j = 0; j < userObj.menus[i].submenus.length; j++) {
              if (this.sideMenuUrlMap.get(userObj.menus[i].submenus[j].state) == ComponentUrl) {
                return userObj.menus[i].submenus[j].access;
              }
            }
          } else {
            if (this.sideMenuUrlMap.get(userObj.menus[i].state)  == ComponentUrl) {
              return userObj.menus[i].access;
            }
          }
        }
        this.router.navigate(["error-page", { errorCode: 404 }]);
        return {};
      }
    }
    this.router.navigate(["error-page", { errorCode: 404 }]);
    return {};
  }

  goToFirstLink(){
    let sessionUserObj:any = this.getUser();
    if(sessionUserObj.menus && sessionUserObj.menus.length>0){
      this.router.navigate([this.sideMenuUrlMap.get(sessionUserObj.menus[0].state ? sessionUserObj.menus[0].state : sessionUserObj.menus[0].submenus[0].state)]);
    }else{
      this.router.navigate(["error-page", { errorCode: 404 }]);
    }
  }

  clearUser(){
    localStorage.clear();
  }

  checkEmail(email){
    // email=email.trim();
    var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // var regExp = /(^[a-z]([a-z_\.]*)@([a-z_\.]*)([.][a-z]{2,3})$)|(^[a-z]([a-z_\.]*)@([a-z_\.]*)(\.[a-z]{2,3})(\.[a-z]{2,3})*$)/i;
    // var regExp=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return reg.test(email);
  }
  checkAlertPlaceholderReg(){
    return /^%[A-Za-z][a-zA-Z0-9_]+%$/;
  }
}
